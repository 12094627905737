/* * {
    outline: solid red 1px;
} */

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lexend&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https: //use.typekit.net/rsu4pyh");

/* Colors */
:root {
    --color-default: #FCAB68;
    --color-pink : #FAE1E8;
    --color-brown: #E9D3C1;
    --color-light-green: #EEF9CF;
    --color-mint-green: #BFE4C7;
    --color-hue-pink: #FFDCDC;
    --color-hue-peach: #FFD1C2;
    --color-peach: #FFF4EB;

}

/* Smooth Scroll */
:root {
    scroll-behavior: smooth;
}

/* =============================== GENERAL =================================================== */
body {
    /* font-family: 'Poppins', sans-serif !important; */
    font-family: 'bebas-neue-pro', sans-serif !important;
}

section {
    margin-bottom: 7.5em;
}

a:hover {
    color: var(--color-default) !important;
}

span {
    color: var(--color-default);
}

img {
    border-radius: 7.5px !important;
}

.tt-button {
    border: solid 2px !important;
    border-radius: 6px;
    box-shadow: 5px 5px #000;
}

a .tt-button:hover {
    border: solid black 2px !important;
    color: black !important;
}

@media (min-width:992px) {
    .tt-header-fs {
        font-size:80px;
    }
    .tt-header-fs-slogan {
        font-size: 63px !important;
    }
    .tt-header-hero-fs {
        font-size: 85px !important;
    }
}

@media (max-width: 1199px) {
    .tt-header-fs {
        font-size:40px;
    }
    .tt-header-fs-slogan {
        font-size: 63px !important;
    }
    .tt-header-hero-fs {
        font-size: 45px !important;
    }
}

/* @media (min-width:993px) and (max-width:1199px){
    .tt-header-fs {
            font-size: 40px;
        }
    
        .tt-header-fs-slogan {
            font-size: 63px !important;
        }
    
        .tt-header-hero-fs {
            font-size: 45px !important;
        }
} */

p {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 18px !important;
}

a {
    font-size: 18px !important;
}




/* =============================== NAVIGATION =================================================== */

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.nav-link.active {
    color: var(--color-default) !important;
}

.tt-logo {
    position: relative;
}

.tt-logo::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 5px;
        bottom: -5px;
        width: 100%;
        height: 100%;
        border-radius: 3.25px;
        border: 1px solid var(--color-default);
}

/* =============================== SOCIAL MEDIA =================================================== */
#socialmedia {
    overflow: hidden;
}

.tt-phone {
    height: 600px;
    width: 300px;
    border-radius: 20px;
    box-shadow: 10px 10px rgb(0, 0, 0, 0.5);
    transform: rotate(15deg) translateY(150px);
}

.tt-phone-insta-content {
    width: 100%;
    height: 240px;
    overflow-y: scroll;
}

.tt-phone-insta-content::-webkit-scrollbar {
    display: none;
}

@media (max-width: 992px) {
    .tt-phone {
        transform: rotate(0deg) translateY(140px);
        box-shadow: none;
    }

}

.tt-phone-content-imgs {
    /* max-width: 9px !important; */
    height: 125px !important;
    width: 125px !important;
    object-fit: cover;
}

.tt-phone-two {

    height: 600px;
    width: 300px;
    border-radius: 20px;
    box-shadow: -10px 10px rgb(0, 0, 0, 0.5);
    transform: rotate(-4deg) translateY(100px);

}

.tt-phone-content-two {
    height: 450px;
    overflow-y: scroll;
}

.tt-phone-content-two::-webkit-scrollbar {
    display: none;
}

@media (max-width: 992px) {
    .tt-phone-two {
        display: none;
    }
}

.tt-phone-topbar {
    height: 3%;
}

.tt-phone-topbar-content {
    width: 50%;
    border-radius: 0 0 15px 15px;
}

.tt-phone-content-logo {
    width: 75px;
}


.tt-phone-content-imgs-two {
    width: 250px;
}

.tt-socialmedia-info {
    height: 100% !important;
}

.tt-logo {
    font-family: 'Lexend', sans-serif;
}

/* =============================== FOOTER =================================================== */
.tt-links a {
    text-decoration: none !important;
    color: #000 !important;
}

.tt-links a:hover {
    color: var(--color-default) !important;
}

@media (min-width: 992px) {
    .tt-stay-updated-info {
        width: 50% !important;
    }
}

/* =============================== HOME PAGE =================================================== */


/* ---------- Hero Section ----------*/
.tt-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tt-header-slogan {
    font-size: 70px;
    font-weight: 800 !important;
}

.tt-header-info {
    width: 65% !important;
}

.tt-hero-img {
    box-shadow: 20px 20px var(--color-default);
}

@media (max-width: 992px) {
    .tt-header-slogan {
        font-size: 35px;
    }

    .tt-header-info {
        width: 100% !important;
    }

    .tt-hero-img {
        box-shadow: 10px 10px var(--color-default);
    }
}


/* .tt-hero-img {
    max-width: 750px;
    object-fit: contain;
} */
/* @media (max-width: 1200px) {
    .tt-header-slogan {
        font-size: 55px;
    }
} */

/* ---------- BestSellers Section ----------*/

.tt-watermelon {
    box-shadow: 10px 10px var(--color-pink);
}

.tt-mimosa {
    box-shadow: 10px 10px var(--color-brown);
}

.tt-moscow {
    box-shadow: 10px 10px var(--color-light-green);
}

.tt-margarita {
    box-shadow: 10px 10px var(--color-mint-green);
}

.tt-paloma {
    box-shadow: 10px 10px var(--color-hue-pink);
}

.tt-bellini {
    box-shadow: 10px 10px var(--color-hue-peach);
}

.tt-spicy-margarita {
    box-shadow: 10px 10px var(--color-peach);
}


.tt-bestsellers-img-container {
    max-width: 350px;
    min-height: 445px !important;
}

.tt-bestsellers-img-container:hover {
    cursor: pointer;
}



@media (min-width: 992px) {
    .tt-bestsellers-img-container {
        min-height: 600px !important;
    }
}

@media (max-width: 1190px) {
    .tt-bestsellers-img-container h3 {
            min-height: 20% !important;
        }
}

/* ---------- OurProducts Section ----------*/
.tt-ourproducts-description {
    border-radius: 10px !important;
}

@media(min-width: 992px) {
    .tt-ourproducts-description {
        max-width: 70vw;
    }
}

.tt-ourproducts-icon {
    height: 75px !important;
    width: 75px !important;
    border-radius: 100%;
}

@media (min-width: 992px) {
    .tt-single-product-info {
        width: 80% !important;
    }
}

/* ---------- Slogan Section ----------*/

.tt-slogan-img {
    box-shadow: 7.5px 7.5px #FAE1E8;
}

@media (min-width: 992px) {
    .tt-slogan-img {
            max-width: 300px !important;
            box-shadow: 10px 10px #FAE1E8;
        }
}

/* ---------- Partners Section ----------*/
.tt-partners-container {
    box-shadow: 10px 10px #E9D3C1;
}


.tt-partners-img {
    max-width: 150px !important;
}

@media (max-width: 992px) {
    .tt-partners-img {
        max-width: 100px !important
    }
}


/* ---------- Customer Reviews Section ----------*/

.tt-individual-reviews {
    min-height: 310px;
    box-shadow: 10px 10px rgb(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
    .tt-individual-reviews {
        min-height: 275px;
    }
}


@media (max-width: 576px) {
    .tt-individual-reviews {
        box-shadow: none;
    }
}


.swiper-nav-btns button {
    border: solid black 2px !important;
    border-radius: 100% !important;
    background-color: white !important;
}

.swiper-nav-btns i {
    -webkit-text-stroke: 1px !important;
}

/* =============================== ABOUT PAGE =================================================== */

/* ---------- Who We Are Section ----------*/
.tt-green-color {
    color: #7EDC92;
}

@media (max-width: 576px) {
    .tt-about-text {
        width: 100% !important;
    }
}

@media (min-width: 992px) {
    .tt-about-text {
        width: 80% !important;
    }
}

.tt-aboutus-info {
    height: 600px;
    width: 425px;
    border-radius: 12px;
}

.tt-slogantwo-description {
    border-radius: 10px !important;
    max-width: 70vw;
    height: 250px;
}

.tt-about-smaller-img {
    height: 175px;
    width: 200px;
}

.tt-about-larger-img {
    height: 250px !important;
    width: 300px;
}

/* team page */
.tt-team-card {
    max-width: 300px !important;
    overflow: hidden !important;
    min-height: 475px;
}

.tt-team-img {
    border: none !important;
    height: 410px !important;
    width: 465px !important;
    object-fit: cover;

}

.tt-team-img-flipped {
    border: none !important;
    height: 100% !important;
    object-fit: cover;
    min-height: 475px;
}

.tt-section-one > img {
    max-height: 500px;
    object-fit: cover;
}



/* --------------------------------------------------------------------------------------------------------------------------------------------PRODUCTS PAGE --------------------------------------------------------------------------------------------------------------*/

.tt-more-to-come {
    height: 415px !important;
}

.tt-more-to-come>div {
    width: 80%;
    box-shadow: 10px 10px #000;
}

@media (max-width: 576px) {
    .tt-more-to-come>div {
        width: 100%;
    }

    .tt-more-to-come input {
        width: 100%;
    }
}

.tt-more-to-come-text {
    width: 65%;
}

.tt-products-img-container:hover {
    cursor: pointer;
}

.tt-products-img-container img {
    /* max-width: 225px; */
    max-height: 318px;
}

.tt-display-imgs {
    max-height: 150px !important;
}

.tt-display-imgs:hover {
    cursor: pointer;
}



@media (max-width: 992px) {
    .tt-more-to-come-text {
        width: 100%;
    }

}

@media (min-width: 992px) {
    .tt-products-img-container img {
            /* max-width: 225px; */
            max-height: 318px;
            width: auto;
        }
}




/* =============================== LOCATIONS PAGE =================================================== */

/* ---------- Maps ----------*/
.tt-details-card {
    position: absolute;
    z-index: 1;
    width: 175px;
    top: -140px;
    left: -80px;
}

.tt-details-card-two {
    max-width: 150px;
}

.tt-react-maps {
    height: 750px;
}



@media (max-width: 768px) {
    .tt-react-maps {
        height: 50vh;
    }

}

.tt-pins {
    /* transform: translate(-50%, -50%) !important */
    margin-top: -34px !important;
        margin-left: -17px !important;
}


/* ---------- Panel ----------*/
.tt-remove-img-css{
    position: relative;
}

.panel {
    position: absolute;
    /* top: 350px;
    left: 3vw ; */
    top: 50%;
    left:20vw;
    transform: translate(-50%, -50%);
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.65);
}

.tt-626-box {
    border-radius: 10% !important;
    max-width: 40px !important;
}

@media (max-width: 992px) {

    .panel {
        position:relative;
        top: 0;
        left: 0;
        max-width: 100%;
        transform: translate(0%, 0%);
        width: auto;
    }
}

.results {
    overflow-x: hidden;
    overflow-y: auto;
    height: 480px;
}


.tt-card-container {
    box-shadow: 10px 10px #000000;
    width: 95%;
}

.tt-card-container:hover {
    cursor: pointer;
}

.results::-webkit-scrollbar-track {
    background-color: transparent;
}

.results::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.results::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(30, 30, 30, 0.5)
}

.tt-panel-input {
    border: none !important;
}

.tt-panel-select {
    border: 2px solid black !important;
}


/* =============================== EVENTS PAGE =================================================== */
@media (max-width: 768px) {
    .tt-upcoming-events-container {
        cursor: pointer;
    }
}

.tt-card-events {
    max-width: 350px !important;
    box-shadow: 10px 10px var(--color-default);
}

.tt-calendar-container {
    /* max-height: 85vh !important; */
    width: 100%;

}
.tt-search-events-container {
    border-right: solid black 2px;
}

.tt-calendar-results-container {
    max-height: 65vh;
    overflow-y: scroll;
}

.tt-events-results-card {
    box-shadow: 7.5px 7.5px var(--color-default);
    max-width: 875px !important;
    cursor: pointer;
}

.tt-events-card-header {
    z-index: -99;
    border-right: solid #000 2px !important;
}

.tt-modal-card {
    box-shadow: 10px 10px var(--color-default);
}

@media (min-width: 1140px) {
    .tt-events-results-card {
        min-width: 500px;
    }

    .tt-search-bar-container > div {
        min-width: 300px;
    }
}


/* =============================== CONTACT PAGE =================================================== */

.tt-form-container {
    width: 750px !important;
}

.bi-check-circle {
    font-size: 3em;
}

/* =============================== VERIFICATION PAGE =================================================== */
.tt-verification-container {
    background: linear-gradient(180deg, #FCC168 0%, #FCAB68 100%);
}

.tt-verification-card {
    max-width: 600px;
    box-shadow: 10px 10px #000;
}

.tt-verification-card>h3 {
    font-family: 'Lexend', sans-serif;
}

/* =============================== SINGLE PRODUCT PAGE =================================================== */
.tt-single-product-thumbnails img {
    height: 100%;
}


/* =============================== SINGLE PRODUCT PAGE =================================================== */
.tt-policy {
    max-width: 650px !important;
}

