// color themes
$primary: #FCAB68;
$secondary: #FFF1F5;
$success: #FFF4EB;
$info: #EEFCF1;


// links
$link-hover-color: #FCAB68;

// carousel
$carousel-control-color : #000;
$carousel-control-opacity: 1;
$carousel-control-icon-width: 1.5em;



@import 'node_modules/bootstrap/scss/bootstrap.scss'